<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form
            ref="form"
            v-model="valid">
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm8
                  md6
                >
                  <v-text-field
                    v-model="code"
                    :label="$t('common.code')"
                    :name="$t('common.code')"
                    type="text"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
              >
                <span style="text-transform: none;">{{
                  $t("common.save")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('voucherProduct.categoriesList')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddCategory(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <table class="table question-table">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in categoryHeaders"
                  :key="header + index"
                  class="no-wrap"
                  scope="col"
                >
                  {{ $t(header.text) }}
                </th>
              </tr>
            </thead>
            <draggable
              v-model="categoriesList"
              v-bind="dragOptions"
              tag="tbody"
              @update="updateShort"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <tr
                v-for="(item, index) in categoriesList"
                :key="item + index"
                @mouseover="onHoverEmail(item)"
                @mouseleave="onLeaveEmail(item)">
                <td class="text-xs-center">{{ item.stt }}</td>
                <td style="width: 15%; white-space: pre-wrap;">{{ item.code }}</td>
                <td style="width: 80%;">
                  <span class="pb-1">{{ item.name }}
                  </span>
                  <div
                    v-show="item.isShowTool"
                    class="mt-1"
                    style="display: flex;"
                  >
                    <action-item
                      first
                      class="text-success"
                      @click="onShowModalAddCategory(item.id)">
                      {{ $t('common.update') }}
                    </action-item>
                    <action-item
                      style="color: red;"
                      @click="onShowModalConfirmDeleteCategory(item.id)">
                      {{ $t('common.delete') }}
                    </action-item>
                  </div>
                </td>
                <td>
                  <v-menu
                    bottom
                    left
                    offset-y
                    transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="success"
                        style="color: #ffffff;"
                        v-on="on"
                      >
                        <v-icon dark>mdi-cursor-move</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list dense>
                        <v-list-tile
                          v-for="(action, index) in moveActionList"
                          :key="action + index"
                          @click="clickMoveQuestion(action, item)"
                        >
                          <v-list-tile-title v-text="$t(action.text)" />
                        </v-list-tile>
                      </v-list>
                    </v-card>
                  </v-menu>
                </td>
              </tr>
            </draggable>
          </table>
          <!-- <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="categoryHeaders"
            :items="categoriesList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.stt }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td class="text-xs-center">
                <v-menu
                  v-model="item.selectedButton"
                  offset-y
                  transition="slide-y-transition"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="indigo"
                      dark
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalAddCategory(item.id)">
                          <v-icon dark>mdi-square-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.update') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="red"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalConfirmDeleteCategory(item.id)">
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('common.delete') }}</span>
                    </v-tooltip>
                  </v-card>
                </v-menu>
              </td>
            </template>
          </v-data-table> -->
          <pagination
            :total="categoriesPaginate.totalPage"
            :current-page="categoriesPaginate.currentPage"
            :row-per-page="categoriesPaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteCategory"/>
    <addCategoryModal
      ref="addCategoryModal"
      @createCategorySuccess="onCreateCategorySuccess"/>
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import ConfirmModal from 'Components/ConfirmModal'
import functionUtils from 'utils/functionUtils'
import AddCategoryModal from './Add.vue'
import draggable from 'vuedraggable'
import ActionItem from 'Components/ActionItem.vue'
export default {
  components: {
    ConfirmModal,
    AddCategoryModal,
    draggable,
    ActionItem
  },
  data: () => ({
    categoryHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.code',
        value: 'code'
      },
      {
        sortable: false,
        text: 'common.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    categoriesList: [],
    categoriesListClone: [],
    categoriesPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    categoryId: null,
    confirmModalTitle: null,
    isLoading: false,
    valid: false,
    code: null,
    moveActionList: [
      {
        value: 1,
        text: 'voucherProduct.orderPriority.moveToFirst',
        action: 1
      },
      { value: 2, text: 'voucherProduct.orderPriority.moveToUp', action: 3 },
      {
        value: 3,
        text: 'voucherProduct.orderPriority.moveToDown',
        action: 4
      },
      {
        value: 4,
        text: 'voucherProduct.orderPriority.moveToLast',
        action: 2
      }
    ],
    actionOrderPriority: null
  }),
  computed: {
    dragOptions () {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {
  },
  created () {
    this.getCategories()
  },
  methods: {
    updateShort (evt) {
      this.reorder(evt.oldIndex, evt.newIndex)
      this.updateCategoryPriorityCustom()
    },
    reorder (oldIndex, newIndex) {
      // move the item in the underlying array
      // update order property based on position in array
      this.categoriesList.splice(oldIndex, 0, this.categoriesList.splice(oldIndex, 1)[0])
      this.categoriesList.forEach((item, index) => {
        item.stt = this.categoriesListClone[index].stt
        item.priority = this.categoriesListClone[index].priority
      })
    },
    clickMoveQuestion: function (actionValue, item) {
      this.actionOrderPriority = actionValue.action
      this.updateCategoryPriority(actionValue, item)
    },
    updateCategoryPriority: function (actionValue, item) {
      let data = {
        first_category_id: item.id,
        action_type: actionValue.action
      }
      this.UPDATE_PRIORITY_CATEGORY(data)
        .then(
          function () {
            this.getCategories()
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Update category priority
     */
    updateCategoryPriorityCustom: function () {
      let data = {
        categories_list: this.categoriesList,
        action_type: 5
      }
      this.UPDATE_PRIORITY_CATEGORY(data)
    },
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Show modal add category
     */
    onShowModalAddCategory: function (id) {
      this.$refs.addCategoryModal.onShowModal(id)
    },
    /**
     * Create category success
     */
    onCreateCategorySuccess: function () {
      this.getCategories()
    },
    /**
     * Delete domain
     */
    onDeleteCategory: function () {
      this.DELETE_VOUCHER_PRODUCT_CATEGORY({ id: this.categoryId }).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getCategories()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(errorText),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Show modal confirm delete domain
     */
    onShowModalConfirmDeleteCategory: function (id) {
      this.categoryId = id
      this.confirmModalTitle = 'Bạn có chắc chắn muốn xoá danh mục này?'
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.categoriesPaginate.currentPage = page
      this.getCategories()
    },
    /**
     * Get domain
     */
    getCategories: function () {
      let filter = {
        params: {
          code: this.code,
          page: this.categoriesPaginate.currentPage
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_PRODUCT_CATEGORIES(filter).then(
        function (res) {
          this.isLoading = false
          let data = res.data
          // Handle paginate
          this.categoriesPaginate.currentPage = data.page
          this.categoriesPaginate.totalPage = data.num_pages
          this.categoriesPaginate.rowPerPage = data.per_page / 2
          this.categoriesList = []
          this.categoriesListClone = []
          for (let i = 0, size = data.results.length; i < size; i++) {
            let categoryObj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: data.results[i].id,
              name: data.results[i].name,
              code: data.results[i].code,
              isPublished: data.results[i].is_published,
              isShowTool: false,
              priority: data.results[i].priority
            }
            this.categoriesList.push(categoryObj)
            this.categoriesListClone.push({ ...categoryObj })
          }
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHER_PRODUCT_CATEGORIES',
      'DELETE_VOUCHER_PRODUCT_CATEGORY',
      'UPDATE_PRIORITY_CATEGORY'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../styles/material-dashboard/_colors.scss";
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.cursor-pointer {
  cursor: pointer !important;
}
.question-table thead tr {
  color: #0000008a;
}
.question-table tbody tr:hover {
  background-color: #e5e5e5e5 !important;
}
.v-menu__content .v_card .v_list {
  background: red;
}
.v-menu__content .v_card {
  background: red;
  padding: 50px;
}
</style>
