<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="cateogoryId ? $t('portal.category.update') : $t('portal.category.add')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <!-- Code -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="code"
                  :label="$t('common.code')"
                  :name="$t('common.code')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- Name -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="name"
                  :label="$t('common.name')"
                  :name="$t('common.name')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <!-- Public on home portal -->
              <v-flex
                xs12
                md6>
                <v-checkbox
                  v-model="isPublished"
                  :label="$t(`voucherProduct.displayOnHomeScreen`)"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data: () => ({
    code: null,
    isShowModal: false,
    name: null,
    cateogoryId: null,
    valid: false,
    isPublished: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          code: this.code,
          name: this.name,
          isPublished: this.isPublished
        }
        if (this.cateogoryId) {
          this.UPDATE_VOUCHER_PRODUCT_CATEGORY({ id: this.cateogoryId, ...filter })
            .then(
              function () {
                this.$emit('createCategorySuccess')
                this.isShowModal = false
              }.bind(this)
            )
            .catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message: this.$t(errorText),
                    styleType: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styleType: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        } else {
          this.CREATE_VOUCHER_PRODUCT_CATEGORIES(filter)
            .then(
              function () {
                this.$emit('createCategorySuccess')
                this.isShowModal = false
              }.bind(this)
            )
            .catch(
              function (error) {
                if (!functionUtils.isNull(error.response.data.errorText)) {
                  let errorText = error.response.data.errorText
                  this.ON_SHOW_TOAST({
                    message: this.$t(errorText),
                    styleType: ToastType.ERROR
                  })
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t('login.somethingWentWrong'),
                    styleType: ToastType.ERROR
                  })
                }
              }.bind(this)
            )
        }
      }
    },
    /**
     * Show modal
     */
    onShowModal: function (cateogoryId) {
      this.cateogoryId = cateogoryId
      if (this.cateogoryId) {
        this.GET_VOUCHER_PRODUCT_CATEGORY_DETAIL({ id: this.cateogoryId }).then(
          function (res) {
            let data = res.data
            this.code = data.code
            this.name = data.name
            this.isPublished = data.is_published
          }.bind(this)
        )
      } else {
        this.code = null
        this.name = null
        this.isPublished = false
      }
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions([
      'CREATE_VOUCHER_PRODUCT_CATEGORIES',
      'GET_VOUCHER_PRODUCT_CATEGORY_DETAIL',
      'UPDATE_VOUCHER_PRODUCT_CATEGORY',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
